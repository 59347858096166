import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import { EventService } from "../services/event.service";
import { Link } from "react-router-dom";
import { IEvent } from "../interfaces/Event";

const ViewEvent = () => {

    const { t } = useTranslation(['home'])
    const { id } = useParams()
    const eventService = new EventService();


    const [event, setEvent] = useState<IEvent | null>(null);

    useEffect(() => {
        loadEvent()
    }, [])

    const loadEvent = async () => {
        try {
            const result = await axios.get<IEvent>(`${process.env.REACT_APP_API_BASE_URL}/v1/events/${id}`)
            setEvent(result.data)
        } catch (error) {
            console.error("Error loading event:", error);
        }
    }

    if (!event) {
        return <p>{t("Loading...")}</p>;
    }

    return (
    <section>
            <p>
                <b>{t("Event Id")}:</b> {event.id}
            </p>
            <p>
                <b>{t("Event Name")}:</b> {event.eventName}
            </p>
            <p>
                <b>{t("Description")}:</b> {event.description}
            </p>
            <p>
                <b>{t("Start Date")}:</b> {event.startDate}
            </p>
            <p>
                <b>{t("End Date")}:</b> {event.endDate}
            </p>
            <p>
                <b>{t("Start Time")}:</b> {event.startTime}
            </p>
            <p>
                <b>{t("End Time")}:</b> {event.endTime}
            </p>
            <p>
                <b>{t("All Day")}:</b> {event.allDay ? t("Yes") : t("No")}
            </p>
            <p>
                <b>{t("Category")}:</b> {event.category}
            </p>
            <p>
                <b>{t("Attendees")}:</b> {event.maxAttendees}
            </p>
            <p>
                <b>{t("Location")}:</b> {event.location?.locationName}
            </p>

            <Link to={"/events"} className="btn mt-8">
                {t("Cancel")}
            </Link>
        </section>)
}

export default ViewEvent
