import { useState } from 'react';
import { Link } from 'react-router-dom';
import ClickOutside from '../ClickOutside';
import { useTranslation } from "react-i18next";

import { MdLanguage } from "react-icons/md";
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';

const DropdownMessage = () => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifying, setNotifying] = useState(true);

  const { t, i18n } = useTranslation(['home']);
  const onClickLanguageChange = (e: any) => {
    const language = e.target.value;
    i18n.changeLanguage(language); //change the language
    flatpickr.localize(German);
  }
  return (
    <select className="select select-sm max-w-xs text-white" onChange={onClickLanguageChange}>
      <option value="de" >Deutsch</option>
      <option value="en" >English</option>
    </select>
  );
};

export default DropdownMessage;
