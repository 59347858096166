import { IEvent } from "./Event";

export interface IProject {
    id?: number | null;
    projectNr: string;
    countryCode: string;
    language: string;
    projectType: string;
    projectStatus: string;
    startDate: string;
    endDate: string;
}

export class Project implements IProject {
    public id: null;
    public projectNr: string;
    public countryCode: string;
    public language: string;
    public projectType: string;
    public projectStatus: string;
    public startDate: string;
    public endDate: string;

    constructor() {
        this.id = null;
        this.projectNr = "";
        this.countryCode = "";
        this.language = "";
        this.projectType = "";
        this.projectStatus = "";
        this.startDate = "";
        this.endDate = "";
    }
}
