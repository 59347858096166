import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEvent, Event } from '../../interfaces/Event';

export interface EventState {
	data: IEvent;
	list: IEvent[]
}

const initialState: EventState = {
	data: new Event(),
	list: []
} 

export const eventSlice = createSlice({
	name: 'event',
	initialState,
	reducers: { 
		setData: (state, action: PayloadAction<IEvent>) => {
			state.data = action.payload
		},
		setEvents: (state, action: PayloadAction<IEvent[]>) => {
			state.list = action.payload
		},
	}
})

export const { setData, setEvents } = eventSlice.actions
export default eventSlice.reducer
