import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ProjectState, setProjectData, setProjects } from '../../features/slices/projectSlice';
import { ProjectService } from '../../services/project.service';
import { IProject, Project } from '../../interfaces/Project';
import {toast} from "react-hot-toast";
import Breadcrumb from "../Breadcrumbs/Breadcrumb";
import userThree from "../../images/user/user-03.png";

export const FormProject = () => {

    let navigate = useNavigate();

    const { t } = useTranslation(['home']);

    const { project } = useSelector((state: { project: ProjectState }) => state);

    const [errorForm, setErrorForm] = useState({
        projectNr: false,
        countryCode: false,
        language: false,
        projectType: false,
        projectStatus: false,
        startDate: false,
        endDate: false,
    })

    const dispatch = useDispatch();

    const projectService = new ProjectService();

    const setFormValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setProjectData({ ...project.data, [event.target.id]: event.target.value }))
    }

    const isValidForm = () => {

        const error = { projectNr: false, countryCode: false, language: false, projectStatus: false, projectType: false, startDate: false, endDate: false}

        if (!project.data.projectNr) error.projectNr = true
        if (!project.data.countryCode) error.countryCode = true;
        if (!project.data.projectStatus) error.projectStatus = true
        if (!project.data.projectType) error.projectType = true
        if (!project.data.startDate) error.startDate = true
        if (!project.data.endDate) error.endDate = true
        if (!project.data.language) error.language = true;

        setErrorForm(error)

        return error.projectNr || error.countryCode || error.language;
    }

    const fetchUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            const data: IProject = await projectService.put(project.data)
            // add item
            const dataArray: IProject[] = [...project.list]
            // search index 
            let index: number = dataArray.findIndex((item: IProject) => item.id === data.id)
            // replace item 
            dataArray.splice(index, 1, data);
            //update item
            dispatch(setProjects(dataArray))
            // for clean form
            dispatch(setProjectData(new Project()))

            toast.success(t('The data has been updated'))
            navigate("/projects")
        } catch (error) {
            console.log(error)
        }
    }

    const fetchCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            // valid fields 
            if (isValidForm()) return null;

            const data: IProject = await projectService.post(project.data)
            // for clean form
            dispatch(setProjectData(new Project()))
            // add item
            const dataArray: IProject[] = [...project.list]
            dataArray.push(data)
            dispatch(setProjects(dataArray))

            toast.success(t('The data has been saved'));
            navigate("/projects")
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error);
            }
        }
    }

    const inputCSS = "w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
    const labelCSS = "mb-3 block text-sm font-medium text-black dark:text-white"
    const inputError = "border-red-400"

    return (
    <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Add Project")}/>

        <div className="grid grid-cols-5 gap-8">
            <div className="col-span-5 xl:col-span-5">
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">
                            {t("Add Project")}
                        </h3>
                    </div>

                    <div className="p-7">
                        <form onSubmit={(e) => project.data.id ? fetchUpdate(e) : fetchCreate(e)}>
                            <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                                <div className="w-full sm:w-1/2">
                                    <label
                                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                                        htmlFor="projectNr"
                                    >
                                        {t("Project Nr")}
                                    </label>
                                    <div className="relative">
                                    <span className="absolute left-4.5 top-4">
                                    </span>
                                        <input
                                            className={errorForm.projectNr ? inputCSS + inputError : inputCSS}
                                            id="projectNr"
                                            type="text"
                                            placeholder={t("Project Nr")}
                                            value={project.data.projectNr}
                                            onChange={(e) => setFormValue(e)}
                                        />
                                        {errorForm.projectNr &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="w-full sm:w-1/2">
                                    <label
                                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                                        htmlFor="projectStatus"
                                    >
                                        {t("Project State")}
                                    </label>
                                    <input
                                        className={errorForm.projectStatus ? inputCSS + inputError : inputCSS}
                                        id="projectStatus"
                                        type="text"
                                        placeholder={t("PUBLISHED, DRAFT, REVIEW")}
                                        value={project.data.projectStatus}
                                        onChange={(e) => setFormValue(e)}
                                    />
                                    {errorForm.projectStatus &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="projectType">
                                    {t("Project Type")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.projectType ? inputCSS + inputError : inputCSS}
                                        id="projectType"
                                        type="text"
                                        placeholder={t("INTERNAL, EXTERNAL")}
                                        value={project.data.projectType}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.projectType &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="projectType">
                                    {t("Start Date")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.startDate ? inputCSS + inputError : inputCSS}
                                        id="startDate"
                                        type="date"
                                        placeholder={t("startDate")}
                                        value={project.data.startDate}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.startDate &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="countryCode">
                                    {t("End Date")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.endDate ? inputCSS + inputError : inputCSS}
                                        id="endDate"
                                        type="date"
                                        value={project.data.endDate}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.endDate &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="language" >
                                    {t("Language")}
                                </label>
                                <input
                                    className={errorForm.language ? inputCSS + inputError : inputCSS}
                                    id="language"
                                    type="text"
                                    placeholder={t("de, en, pt, ...")}
                                    value={project.data.language}
                                    onChange={(e) => setFormValue(e)}
                                />
                                {errorForm.language &&
                                    <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="countryCode">
                                    {t("Country Code")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.countryCode ? inputCSS + inputError : inputCSS}
                                        id="countryCode"
                                        type="text"
                                        placeholder={t("DE, EN, BR, ...")}
                                        value={project.data.countryCode}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.countryCode &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="flex justify-end gap-4.5">
                                <Link to={"/projects"} className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white" type="submit" >
                                    {t("Cancel")}
                                </Link>

                                <button className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90" type="submit" >
                                    {project.data.id ? t("Save") : t("Create")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
