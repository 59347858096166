import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserService } from "../services/user.service";
import axios from "axios";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";

const ViewUser = () => {

    const { t } = useTranslation(['home'])

    const userService = new UserService();

    const{id} = useParams()

    const [user, setUser] = useState({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: null,
        courseStart: null,
        receiveNewsletter: false,
        receiveLTMails: false,
        registeredPL: false,
        roles: [],
        createdAt: null
    })

    useEffect(() => {
        loadUser()
    }, [])

    const loadUser = async () => {
        const result = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/users/${id}`)
        setUser(result.data)
    }

    return (
        <div className="mx-auto max-w-270">
            <Breadcrumb pageName={t("View User")}/>

            <div className="grid grid-cols-5 gap-8">
                <div className="col-span-5 xl:col-span-5">
                    <div
                        className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                                {t("View User")}
                            </h3>
                        </div>
                        <div className="p-7">
                            <table className="w-full table-auto">
                                <tbody>
                                <tr>
                                    <th>{t("Username")}:</th><td> {user.username}</td>
                                </tr>
                                <tr>
                                    <th>{t("Firstname")}:</th><td> {user.firstName}</td>
                                </tr>
                                <tr>
                                    <th>{t("Lastname")}:</th><td> {user.lastName}</td>
                                </tr>
                                <tr>
                                    <th>{t("Email")}:</th><td> {user.email}</td>
                                </tr>
                                <tr>
                                    <th>{t("Phone")}:</th><td> {user.phone}</td>
                                </tr>
                                <tr>
                                    <th>{t("Start date")}:</th><td> {user.courseStart}</td>
                                </tr>
                                <tr>
                                    <th>{t("Newsletter")}:</th><td> {user.receiveNewsletter.toString()}</td>
                                </tr>
                                <tr>
                                    <th>{t("LT Mail")}:</th><td> {user.receiveLTMails.toString()}</td>
                                </tr>
                                <tr>
                                    <th>{t("Registered")}:</th><td> {user.registeredPL.toString()}</td>
                                </tr>
                                <tr>
                                    <th>{t("Roles")}:</th><td> {user.roles.join(", ")}</td>
                                </tr>
                                <tr>
                                    <th>{t("Created")}:</th><td> {user.createdAt}</td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="flex justify-end gap-4.5">
                                <Link to={"/users"}
                                      className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                                      type="submit">
                                    {t("Cancel")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewUser
