import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocation, Location } from '../../interfaces/Location';

export interface LocationState {
	data: ILocation;
	list: ILocation[]
}

const initialState: LocationState = {
	data: new Location(),
	list: []
} 

export const locationSlice = createSlice({
	name: 'location',
	initialState,
	reducers: { 
		setLocationData: (state, action: PayloadAction<ILocation>) => {
			state.data = action.payload
		},
		setLocations: (state, action: PayloadAction<ILocation[]>) => {
			state.list = action.payload
		},
	}
})

export const { setLocationData, setLocations } = locationSlice.actions
export default locationSlice.reducer
