import { FormLocation } from "../components/Forms/FormLocation"

const EditLocation = () => {

    return (
        <div className="w-full">
            <FormLocation />
        </div>
    )
}

export default EditLocation
