import React from "react"
import {FormUser} from "../components/Forms/FormUser";

const AddUser = () => {
    return (
        <div className="w-full">
            <FormUser />
        </div>
    )
}

export default AddUser
