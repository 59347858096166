import { configureStore} from '@reduxjs/toolkit'
import userReducer from '../features/slices/userSlice'
import eventReducer from '../features/slices/eventSlice'
import projectReducer from '../features/slices/projectSlice'
import locationReducer from '../features/slices/locationSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    plEvent: eventReducer,
    location: locationReducer,
    project: projectReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(), 
})

export type RootState = ReturnType<typeof store.getState>

export default store
