import React from "react"
import { FormProject } from "../components/Forms/FormProject"

const AddProject = () => {
    return (
        <div className="w-full">
            <FormProject />
        </div>
    )
}

export default AddProject
