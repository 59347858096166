import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProject, Project } from '../../interfaces/Project';

export interface ProjectState {
	data: IProject;
	list: IProject[]
}

const initialState: ProjectState = {
	data: new Project(),
	list: []
}

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setProjectData: (state, action: PayloadAction<IProject>) => {
			state.data = action.payload
		},
		setProjects: (state, action: PayloadAction<IProject[]>) => {
			state.list = action.payload
		},
	}
})

export const { setProjectData, setProjects } = projectSlice.actions
export default projectSlice.reducer
