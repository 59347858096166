import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importiere das Quill CSS für das Styling
import {IEvent, Event} from "../../interfaces/Event";
import {EventState, setData, setEvents} from '../../features/slices/eventSlice';
import {EventService} from '../../services/event.service';
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {RootState} from '../../app/store'; // Importiere den RootState
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import flatpickr from 'flatpickr';
import {German} from 'flatpickr/dist/l10n/de'
import {toast} from "react-hot-toast";

export const FormEvent = () => {

    let navigate = useNavigate();

    const {t} = useTranslation(['home']);

    const {plEvent} = useSelector((state: { plEvent: EventState }) => state);

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const [errorForm, setErrorForm] = useState({
        eventName: false,
        description: false,
        startDate: false,
        endDate: false,
        startTime: false,
        endTime: false,
        allDay: false,
        category: false,
        maxAttendees: false,
        eventCategory: false,
    });
    const [isHtmlMode, setIsHtmlMode] = useState(false);
    const toggleHtmlMode = () => {
        setIsHtmlMode(!isHtmlMode);
    };

    const dispatch = useDispatch();

    const eventService = new EventService();

    const setFormValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setData({...plEvent.data, [event.target.id]: event.target.value}));
    };

    const setDescription = (value: string) => {
        const cleanValue = value === "<p><br></p>" ? "" : value;
        dispatch(setData({...plEvent.data, description: cleanValue}));
    };

    const isValidForm = () => {
        const error = {
            eventName: false,
            description: false,
            startDate: false,
            endDate: false,
            startTime: false,
            endTime: false,
            allDay: false,
            category: false,
            maxAttendees: false,
            eventCategory: false
        };

        if (!plEvent.data.eventName) error.eventName = true;
        if (!plEvent.data.description) error.description = true;
        if (!plEvent.data.startDate) error.startDate = true;
        if (!plEvent.data.endDate) error.endDate = true;
        if (!plEvent.data.startTime) error.startTime = true;
        if (!plEvent.data.endTime) error.endTime = true;
        if (!plEvent.data.category) error.category = true;
        if (plEvent.data.maxAttendees === null || plEvent.data.maxAttendees === undefined) error.maxAttendees = true;
        if (!plEvent.data.eventCategories) error.eventCategory = true;

        setErrorForm(error);
        return Object.values(error).some(val => val);
    };

    const fetchUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            const data: IEvent = await eventService.put(plEvent.data);
            const dataArray: IEvent[] = [...plEvent.list];
            let index: number = dataArray.findIndex((item: IEvent) => item.id === data.id);
            dataArray.splice(index, 1, data);
            dispatch(setEvents(dataArray));
            dispatch(setData(new Event()));

            toast.success(t('The data has been updated'));
            navigate("/events");
        } catch (error) {
            console.log(error);
        }
    };

    const fetchCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            if (isValidForm()) return null;

            const data: IEvent = await eventService.post(plEvent.data);
            dispatch(setData(new Event()));
            const dataArray: IEvent[] = [...plEvent.list];
            dataArray.push(data);
            dispatch(setEvents(dataArray));

            toast.success(t('The data has been saved'));
            navigate("/events");
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error);
            }
        }
    };

    const inputCSS = "w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary";
    const inputError = "border-red-400";
    const labelCSS = "mb-3 block text-black dark:text-white";

    useEffect(() => {
        flatpickr('.form-datepicker', {
            mode: 'single',
            static: true,
            monthSelectorType: 'static',
            enableTime: false,
            altInput: true,
            dateFormat: 'Y-m-d',
            altFormat: 'd.m.Y',
            locale: German,
            time_24hr: true,
        });
        flatpickr('.form-timepicker', {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            locale: German,
            time_24hr: true,
        });
    }, []);

    return (
        <div className="mx-auto max-w-270">
            <Breadcrumb pageName="Add Event"/>

            <div className="grid grid-cols-5 gap-8">
                <div className="col-span-5 xl:col-span-5">
                    <div
                        className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                                {t("Add Project")}
                            </h3>
                        </div>

                        <div className="p-7">
                            <form onSubmit={(e) => plEvent.data.id ? fetchUpdate(e) : fetchCreate(e)}>
                                <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">

                                    <div className="w-full sm:w-1/2">
                                        <label className={`${labelCSS}`} htmlFor="projectId" > {t("Project Id")} </label>
                                        <div className="relative">
                                            <input
                                                id="projectId"
                                                type="text"
                                                placeholder={t("1, 2, ..")}
                                                value={plEvent.data.project?.id!}
                                                onChange={(e) => setFormValue(e)}
                                                className={`${inputCSS}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="w-full sm:w-1/2">
                                        <label className={`${labelCSS}`} htmlFor="location">{t("Location Id")}</label>
                                        <div className="relative">
                                            <input
                                                id="location"
                                                type="text"
                                                placeholder={t("Ort")}
                                                value={plEvent.data.location?.id!}
                                                onChange={(e) => setFormValue(e)}
                                                className={`${inputCSS}`}
                                            />
                                        </div>
                                    </div>
                                    </div>

                                    <div className="mb-5.5">
                                    <label className={`${labelCSS}`} htmlFor="eventName">{t("Event Name")}</label>
                                    <div className="relative">
                                        <input
                                            id="eventName"
                                            type="text"
                                            placeholder={t("Event Name")}
                                            value={plEvent.data.eventName}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.eventName ? `${inputCSS} ${inputError}` : inputCSS}
                                        />
                                        {errorForm.eventName &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className={`${labelCSS}`} htmlFor="description">{t("Description")}</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={plEvent.data.description || ""} // Sicherstellen, dass ein leerer String als Default gesetzt ist
                                        onChange={setDescription}
                                        className={errorForm.description ? `${inputCSS} ${inputError}` : inputCSS}
                                    />
                                    {errorForm.description &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>

                                <div className="mb-5.5">
                                    <p><a onClick={toggleHtmlMode}>
                                        {isHtmlMode ? t("Hide HTML") : t("Show HTML")}
                                    </a></p>
                                    {isHtmlMode ? (
                                        <textarea
                                            id="description"
                                            value={plEvent.data.description}
                                            onChange={(e) => setDescription(e.target.value)} // Verwende setDescription
                                            style={{width: '100%', height: '200px'}}
                                        />
                                    ) : null}
                                </div>

                                <div className="mb-5.5">
                                    <label className={`${labelCSS}`} htmlFor="maxAttendees">{t("Attendees")}</label>
                                    <div className="relative">
                                        <input
                                            id="maxAttendees"
                                            type="number"
                                            placeholder={t("0")}
                                            value={plEvent.data.maxAttendees ?? ""}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.maxAttendees ? `${inputCSS} ${inputError}` : inputCSS}
                                        />
                                        {errorForm.maxAttendees &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <label className={`${labelCSS}`} htmlFor="startDate">
                                        {t("Start Date")}
                                    </label>
                                    <div className="relative">
                                        <input
                                            id="startDate"
                                            className={errorForm.startDate ? `${inputCSS} ${inputError}` : inputCSS}
                                            type="date"
                                            value={plEvent.data.startDate}
                                            onChange={(e) => setFormValue(e)}
                                        />
                                    </div>
                                    {errorForm.startDate &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>

                                <div className="mt-4">
                                    <label className={`${labelCSS}`} htmlFor="endDate">{t("End Date")}</label>
                                    <div className="relative">
                                        <input
                                            id="endDate"
                                            className={errorForm.endDate ? `${inputCSS} ${inputError}` : inputCSS}
                                            type="date"
                                            value={plEvent.data.endDate}
                                            onChange={(e) => setFormValue(e)}
                                        />
                                    </div>
                                    {errorForm.endDate &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>

                                <div className="mt-4">
                                    <label className={`${labelCSS}`} htmlFor="startTime">{t("Start Time")}</label>
                                    <input
                                        id="startTime"
                                        type="text"
                                        className={errorForm.startTime ? `${inputCSS} ${inputError}` : inputCSS}
                                        value={plEvent.data.startTime}
                                        placeholder="hh:mm"
                                        onChange={(e) => setFormValue(e)}
                                    />
                                    {errorForm.startTime &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>

                                <div className="mt-4">
                                    <label className={`${labelCSS}`} htmlFor="endTime">{t("End Time")}</label>
                                    <input
                                        id="endTime"
                                        type="text"
                                        className={errorForm.endTime ? `${inputCSS} ${inputError}` : inputCSS}
                                        value={plEvent.data.endTime}
                                        placeholder="hh:mm"
                                        onChange={(e) => setFormValue(e)}
                                    />
                                    {errorForm.endTime &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>

                                <div className="mt-4 mb-5.5">
                                    <div>
                                        <label
                                            htmlFor="allDay"
                                            className="flex cursor-pointer select-none items-center"
                                        >
                                            <div className="relative">
                                                <input
                                                    type="checkbox"
                                                    id="allDay"
                                                    checked={plEvent.data.allDay}
                                                    onChange={(e) => {
                                                        dispatch(setData({...plEvent.data, allDay: e.target.checked}));
                                                    }}
                                                    className="sr-only"
                                                />
                                                <div
                                                    className={`mr-4 flex h-5 w-5 items-center justify-center rounded border ${plEvent.data.allDay && 'border-primary bg-gray dark:bg-transparent'
                                                    }`}
                                                >
                                                    <span
                                                        className={`opacity-0 ${plEvent.data.allDay && '!opacity-100'}`}>
                                                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                                fill="#3056D3" stroke="#3056D3" strokeWidth="0.4">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            {t("All day")}
                                        </label>
                                    </div>
                                    {errorForm.allDay &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>

                                <div className="flex justify-end gap-4.5">
                                    <Link to={"/events"}
                                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                                          type="submit">
                                        {t("Cancel")}
                                    </Link>

                                    <button
                                        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                                        type="submit">
                                        {plEvent.data.id ? t("Save") : t("Create")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
