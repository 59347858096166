import { FormUser } from "../components/Forms/FormUser"

const EditUser = () => {

    return (
        <div className="w-full">
            <FormUser />
        </div>
    )
}

export default EditUser
