import axios from 'axios';
import { IForm } from '../interfaces/Form';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/forms`;

export const FormService = {
    getAllForms: async (): Promise<IForm[]> => {
        try {
            const response = await axios.get<IForm[]>(API_BASE_URL);
            return response.data;
        } catch (error) {
            console.error('Error fetching forms:', error);
            throw error;
        }
    },

    getFormById: async (id: number): Promise<IForm> => {
        try {
            const response = await axios.get<IForm>(`${API_BASE_URL}/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching form with id ${id}:`, error);
            throw error;
        }
    },

    createForm: async (form: IForm): Promise<IForm> => {
        try {
            const response = await axios.post<IForm>(API_BASE_URL, form);
            return response.data;
        } catch (error) {
            console.error('Error creating form:', error);
            throw error;
        }
    },

    updateForm: async (id: number, form: IForm): Promise<IForm> => {
        try {
            const response = await axios.put<IForm>(`${API_BASE_URL}/${id}`, form);
            return response.data;
        } catch (error) {
            console.error(`Error updating form with id ${id}:`, error);
            throw error;
        }
    },

    deleteForm: async (id: number): Promise<void> => {
        try {
            await axios.delete(`${API_BASE_URL}/${id}`);
        } catch (error) {
            console.error(`Error deleting form with id ${id}:`, error);
            throw error;
        }
    }
};
