import { FormProject } from "../components/Forms/FormProject"

const EditProject = () => {

    return (
        <div className="w-full">
            <FormProject />
        </div>
    )
}

export default EditProject
