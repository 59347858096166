import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
}); 

export const headerAPI = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}
