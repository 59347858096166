import React from 'react';
import { IUser, User } from "../../interfaces/User";
import { useDispatch, useSelector } from "react-redux";
import { UserState, setData, setUsers } from '../../features/slices/userSlice';
import { UserService } from "../../services/user.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import Breadcrumb from "../Breadcrumbs/Breadcrumb";

export const FormUser = () => {

    let navigate = useNavigate();

    const { t } = useTranslation(['home']);

    const { user } = useSelector((state: { user: UserState }) => state);

    const [errorForm, setErrorForm] = useState({
        firstName: false,
        lastName: false,
        username: false,
        enabled: false,
        email: false,
        phone: false,
        courseStart: false
    })

    const dispatch = useDispatch();

    const userService = new UserService();

    const setFormValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setData({ ...user.data, [event.target.id]: event.target.value }))
    }

    const isValidForm = () => {

        const error = { firstName: false, lastName: false, username: false, enabled: false, email: false, phone: false, courseStart: false }

        if (!user.data.firstName) error.firstName = true;
        if (!user.data.lastName) error.lastName = true
        if (!user.data.username) error.username = true
        if (!user.data.enabled) error.enabled = true
        if (!user.data.email) error.email = true;
        if (!user.data.phone) error.phone = true;
        if (!user.data.courseStart) error.courseStart = true;

        setErrorForm(error)

        return error.lastName || error.email || error.phone;
    }

    const fetchUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            const data: IUser = await userService.put(user.data)
            // add item
            const dataArray: IUser[] = [...user.list]
            // search index 
            let index: number = dataArray.findIndex((item: IUser) => item.id === data.id)
            // replace item 
            dataArray.splice(index, 1, data);
            //update item
            dispatch(setUsers(dataArray))
            // for clean form
            dispatch(setData(new User()))

            toast.success(t('The data has been updated'))
            navigate("/users")
        } catch (error) {
            console.log(error)
        }
    }

    const fetchCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            // valid fields 
            if (isValidForm()) return null;

            const data: IUser = await userService.post(user.data)
            // for clean form
            dispatch(setData(new User()))
            // add item
            const dataArray: IUser[] = [...user.list]
            dataArray.push(data)
            dispatch(setUsers(dataArray))

            toast.success('The data has been saved')
            navigate("/users")
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error)
            }
        }
    }

    const inputCSS = "w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
    const labelCSS = "mb-3 block text-sm font-medium text-black dark:text-white"
    const inputError = "border-red-400"

    return (

    <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Add User")}/>

        <div className="grid grid-cols-5 gap-8">
            <div className="col-span-5 xl:col-span-5">
                <div
                    className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">
                            {t("Add User")}
                        </h3>
                    </div>

                    <div className="p-7">
                        <form onSubmit={(e) => user.data.id ? fetchUpdate(e) : fetchCreate(e)}>
                            <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                                <div className="w-full sm:w-1/2">
                                    <label className="mb-3 block text-sm font-medium text-black dark:text-white"
                                           htmlFor="firstName">
                                        {t("Firstname")}
                                    </label>
                                    <div className="relative">
                                    <span className="absolute left-4.5 top-4">
                                    </span>
                                        <input
                                            className={errorForm.firstName ? inputCSS + inputError : inputCSS}
                                            id="firstName"
                                            type="text"
                                            placeholder={t("Maria")}
                                            value={user.data.firstName}
                                            onChange={(e) => setFormValue(e)}
                                        />
                                        {errorForm.firstName &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="w-full sm:w-1/2">
                                    <label className="mb-3 block text-sm font-medium text-black dark:text-white"
                                           htmlFor="lastName">
                                        {t("Lastname")}
                                    </label>
                                    <input
                                        className={errorForm.lastName ? inputCSS + inputError : inputCSS}
                                        id="lastName"
                                        type="text"
                                        placeholder={t("Musterfrau")}
                                        value={user.data.lastName}
                                        onChange={(e) => setFormValue(e)}
                                    />
                                    {errorForm.lastName &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="username">
                                    {t("Username")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.username ? inputCSS + inputError : inputCSS}
                                        id="username"
                                        type="username"
                                        placeholder={t("Username")}
                                        value={user.data.username}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.username && <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>} </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="projectType">
                                    {t("Email")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.email ? inputCSS + inputError : inputCSS}
                                        id="email"
                                        type="email"
                                        placeholder={t("maria@musterfrau.de")}
                                        value={user.data.email}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.email &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="phone">
                                    {t("Phone")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.phone ? inputCSS + inputError : inputCSS}
                                        id="phone"
                                        type="number"
                                        placeholder={t("12345678")}
                                        value={user.data.phone ?? ''}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.phone &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="mb-5.5">
                                <label className={labelCSS} htmlFor="courseStart">
                                    {t("Course Start")}
                                </label>
                                <div className="relative">
                                    <input
                                        className={errorForm.courseStart ? inputCSS + inputError : inputCSS}
                                        id="courseStart"
                                        type="date"
                                        value={user.data.courseStart}
                                        onChange={(e) => setFormValue(e)}
                                    ></input>
                                    {errorForm.courseStart &&
                                        <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                </div>
                            </div>

                            <div className="flex justify-end gap-4.5">
                                <Link to={"/users"}
                                      className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                                      type="submit">
                                    {t("Cancel")}
                                </Link>

                                <button
                                    className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                                    type="submit">
                                    {user.data.id ? t("Save") : t("Create")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
