import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import TableEvents from '../components/Tables/TableEvents';

const Events = () => {
  const { t } = useTranslation(['home']);
  return (
    <>
      <Breadcrumb pageName={t("Events")} />

      <div className="flex flex-col gap-10">
        <TableEvents />
      </div>
    </>
  );
};

export default Events;
