import { useDispatch, useSelector } from "react-redux"
import { ILocation, Location } from "../../interfaces/Location"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa"
import { Link } from "react-router-dom";
import { LocationState, setLocationData, setLocations } from "../../features/slices/locationSlice";
import { LocationService } from "../../services/location.service";
import toast from "react-hot-toast";

const TableLocations = () => {
  const { t } = useTranslation(['home']);

  const { location } = useSelector((state: { location: LocationState }) => state);

  useEffect(() => {
    fetchData() // eslint-disable-next-line
  }, [])

  const locationService = new LocationService();

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const res: ILocation[] = await locationService.getAll()
      dispatch(setLocations(res))
    } catch (error) {
      console.log('Error to failed load ==>', error)
    }
  }

  const onClickDelete = (item: ILocation) => {
    const confirmed = window.confirm(t('Are you sure you want to delete?'));
    if (confirmed) {
      fetchDelete(item);
    }
  }

  const fetchDelete = async (item: ILocation) => {
    try {
      await locationService.delete(item)

      toast.success('The item has been deleted')

      fetchData()

    } catch (error) {
      console.log('Error to failed load ==>', error)
    }
  }

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">

      <div className="flex justify-start gap-4.5 mb-6.5">
        <Link to={"/location-add"} className="btn btn-primary" onClick={() => dispatch(setLocationData(new Location()))}>
          {t("Add new location")}
        </Link>
      </div>

      <div className="max-w-full overflow-x-auto mb-6">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Location Name")}
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Address")}
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                {t("Status")}
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                {t("Actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {location.list.map((item: ILocation, key) => {
              return (
                <tr key={key}>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <Link to={'/location-edit'} className="text-primary dark:text-meta-3" onClick={() => dispatch(setLocationData(item))}>
                      {item.locationName}
                    </Link>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {item.address}, {item.zip} {item.city}, {item.state}, {item.country}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p
                      className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium bg-success text-success}`} >
                      {t("Active")}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <div className="flex items-center space-x-3.5">
                      <Link to={`/location-view/${item.id}`} className="hover:text-white">
                        <FaEye />
                      </Link>

                      <Link to={'/location-edit'} className="hover:text-meta-3" onClick={() => dispatch(setLocationData(item))}>
                        <FaEdit />
                      </Link>

                      <button className="hover:text-white" onClick={() => onClickDelete(item)}>
                        <FaTrashAlt />
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default TableLocations;
