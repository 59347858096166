import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import TableUsers from '../components/Tables/TableUsers';

const Users = () => {
  const { t } = useTranslation(['home']);
  return (
    <>
      <Breadcrumb pageName={t("Users")} />

      <div className="flex flex-col gap-10">
        <TableUsers />
      </div>
    </>
  );
};

export default Users;
