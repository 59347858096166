import './App.css';
import {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';

import AddUser from './pages/AddUser';
import AddLocation from './pages/AddLocation';
import AddEvent from './pages/AddEvent';
import AddProject from "./pages/AddProject";

import EditUser from './pages/EditUser';
import EditLocation from './pages/EditLocation';
import EditEvent from './pages/EditEvent';
import EditProject from './pages/EditProject';

import ViewUser from './pages/ViewUser';
import ViewLocation from './pages/ViewLocation';
import ViewEvent from './pages/ViewEvent';
import ViewProject from './pages/ViewProject';

import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import Calendar from './pages/Calendar';
import Chart from './pages/Chart';
import Overview from './pages/Dashboard/Overview';
import FormElements from './pages/Form/FormElements';
import FormLayout from './pages/Form/FormLayout';
import Events from './pages/Events';
import Settings from './pages/Settings';
import Users from './pages/Users';
import Locations from './pages/Locations';
import Projects from './pages/Projects';
import Alerts from './pages/UiElements/Alerts';
import Buttons from './pages/UiElements/Buttons';
import DefaultLayout from './layout/DefaultLayout';
import {useTranslation} from "react-i18next";
import Bookings from "./pages/Bookings";
import EventBookings from "./pages/EventBookings";
import Categories from "./pages/Categories";
import Forms from "./pages/FormsOverview";
import EditForm from "./pages/EditForm";
import { Toaster } from 'react-hot-toast';


function App() {
    const {t} = useTranslation(['home']);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    return loading ? (
        <Loader/>
    ) : (
        <DefaultLayout>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <Routes>
                <Route
                    index
                    element={
                        <>
                            <PageTitle title="Admin Dashboard"/>
                            <Events/>
                        </>
                    }
                />
                <Route
                    path="/calendar"
                    element={
                        <>
                            <PageTitle title="Calendar"/>
                            <Calendar/>
                        </>
                    }
                />

                <Route
                    path="/events"
                    element={
                        <>
                            <PageTitle title={t("Events")}/>
                            <Events/>
                        </>
                    }
                />
                <Route
                    path='/event-add'
                    element={
                        <>
                            <PageTitle title=""/>
                            <AddEvent/>
                        </>
                    }
                />
                <Route
                    path='/event-edit'
                    element={
                        <>
                            <PageTitle title=""/>
                            <EditEvent/>
                        </>
                    }
                />
                <Route
                    path='/event-view/:id'
                    element={
                        <>
                            <PageTitle title=""/>
                            <ViewEvent/>
                        </>
                    }
                />

                <Route
                    path="/forms/form-elements"
                    element={
                        <>
                            <PageTitle title="FormUser Elements"/>
                            <FormElements/>
                        </>
                    }
                />
                <Route
                    path="/forms/form-layout"
                    element={
                        <>
                            <PageTitle title="FormUser Layout"/>
                            <FormLayout/>
                        </>
                    }
                />

                <Route
                    path="/users"
                    element={
                        <>
                            <PageTitle title={t('Users')}/>
                            <Users/>
                        </>
                    }
                />
                <Route
                    path='/user-add'
                    element={
                        <>
                            <PageTitle title=""/>
                            <AddUser/>
                        </>
                    }
                />
                <Route
                    path='/user-edit'
                    element={
                        <>
                            <PageTitle title=""/>
                            <EditUser/>
                        </>
                    }
                />
                <Route
                    path='/user-view/:id'
                    element={
                        <>
                            <PageTitle title=""/>
                            <ViewUser/>
                        </>
                    }
                />

                <Route
                    path="/projects"
                    element={
                        <>
                            <PageTitle title={t('Projects')}/>
                            <Projects/>
                        </>
                    }
                />
                <Route
                    path='/project-add'
                    element={
                        <>
                            <PageTitle title=""/>
                            <AddProject/>
                        </>
                    }
                />
                <Route
                    path='/project-edit'
                    element={
                        <>
                            <PageTitle title=""/>
                            <EditProject/>
                        </>
                    }
                />
                <Route
                    path='/project-view/:id'
                    element={
                        <>
                            <PageTitle title=""/>
                            <ViewProject/>
                        </>
                    }
                />

                <Route
                    path="/settings"
                    element={
                        <>
                            <PageTitle title="Settings"/>
                            <Settings/>
                        </>
                    }
                />
                <Route
                    path="/chart"
                    element={
                        <>
                            <PageTitle title="Basic Chart"/>
                            <Chart/>
                        </>
                    }
                />
                <Route
                    path="/ui/alerts"
                    element={
                        <>
                            <PageTitle title="Alerts"/>
                            <Alerts/>
                        </>
                    }
                />
                <Route
                    path="/ui/buttons"
                    element={
                        <>
                            <PageTitle title="Buttons"/>
                            <Buttons/>
                        </>
                    }
                />
                <Route
                    path="/auth/signin"
                    element={
                        <>
                            <PageTitle title="Signin"/>
                            <SignIn/>
                        </>
                    }
                />
                <Route
                    path="/auth/signup"
                    element={
                        <>
                            <PageTitle title="Signup"/>
                            <SignUp/>
                        </>
                    }
                />
                <Route
                    path='/locations'
                    element={
                        <>
                            <PageTitle title={t("Locations")}/>
                            <Locations/>
                        </>
                    }
                />
                <Route
                    path='/location-add'
                    element={
                        <>
                            <PageTitle title=""/>
                            <AddLocation/>
                        </>
                    }
                />
                <Route
                    path='/location-edit'
                    element={
                        <>
                            <PageTitle title=""/>
                            <EditLocation/>
                        </>
                    }
                />
                <Route
                    path='/location-view/:id'
                    element={
                        <>
                            <PageTitle title=""/>
                            <ViewLocation/>
                        </>
                    }
                />

                <Route
                    path='/categories'
                    element={
                        <>
                            <PageTitle title={t("Categories")}/>
                            <Categories/>
                        </>
                    }
                />

                <Route
                    path='/bookings'
                    element={
                        <>
                            <PageTitle title={t("Bookings")}/>
                            <Bookings/>
                        </>
                    }
                />

                <Route
                    path='/forms'
                    element={
                        <>
                            <PageTitle title={t("Forms")}/>
                            <Forms/>
                        </>
                    }
                />

                <Route
                    path='/forms/:id/edit'
                    element={
                        <>
                            <PageTitle title={t("Edit FormUser")}/>
                            <EditForm/>
                        </>
                    }
                />

                <Route
                    path="/events/:eventId/bookings"
                    element={
                        <EventBookings/>}
                />

            </Routes>
        </DefaultLayout>
    );
}

export default App;
