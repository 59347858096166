import React from "react"
import {FormLocation} from "../components/Forms/FormLocation";

const AddUser = () => {
    return (
        <div className="w-full">
            <FormLocation />
        </div>
    )
}

export default AddUser
