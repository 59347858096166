import React from "react"
import { FormEvent } from "../components/Forms/FormEvent"

const AddEvent = () => {
    return (
        <div className="w-full">
            <FormEvent />
        </div>
    )
}

export default AddEvent
