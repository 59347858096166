import { useParams } from "react-router-dom";
import TableEventBookings from "../components/Tables/TableEventBookings";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";

const EventBookings: React.FC = () => {
    const { t } = useTranslation(['home']);
    const { eventId } = useParams<{ eventId: string }>();

    if (!eventId) {
        return <div>{t("Event ID is missing")}</div>;
    }

    return (
        <>
            <Breadcrumb pageName={t("Bookings for Event")}/>
            <div className="flex flex-col gap-10">
            <TableEventBookings eventId={parseInt(eventId, 10)} />
            </div>
        </>
    );
};

export default EventBookings;
