import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import { LocationService } from "../services/location.service";
import { Link } from "react-router-dom";

const ViewLocation = () => {

    const { t } = useTranslation(['home'])

    const locationService = new LocationService();

    const { id } = useParams()

    const [location, setLocation] = useState({
        id: null,
        event: null,
        locationName: "",
        description: "",
        address: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        createdAt: null
    })

    useEffect(() => {
        loadLocation()
    }, [])

    const loadLocation = async () => {
        const result = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/locations/${id}`)
        setLocation(result.data)
    }

    return (
        <section>
            <p>
                <b>{t("Location Name")}:</b> {location.locationName}
            </p>
            <p>
                <b>{t("Description")}:</b> {location.description}
            </p>
            <p>
                <b>{t("Created")}:</b> {location.createdAt}
            </p>

            <Link to={"/locations"} className="btn mt-8">
                {t("Cancel")}
            </Link>
        </section>
    )
}

export default ViewLocation
