import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { FormService } from '../services/form.service';
import { IForm, Form } from '../interfaces/Form';
import { useParams } from 'react-router-dom';
import { FormField, IFormField } from "../interfaces/FormField";

const FormEditor: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [form, setForm] = useState<IForm | null>(null);
    const [formFields, setFormFields] = useState<IFormField[]>([]);

    useEffect(() => {
        const loadForm = async () => {
            if (id) {
                const data = await FormService.getFormById(parseInt(id));
                console.log('Loaded form data:', data);
                setForm(data);
                if (Array.isArray(data.formFields)) {
                    setFormFields(data.formFields);
                } else {
                    console.error('formFields is not an array:', data.formFields);
                    setFormFields([]);
                }
                console.log('Set formFields:', data.formFields || []);
            }
        };
        loadForm();
    }, [id]);

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = Array.from(formFields);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Aktualisieren Sie die Reihenfolge, falls nötig
        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index, // Falls Sie eine 'order' Eigenschaft haben
        }));

        setFormFields(updatedItems);
    };

    const saveFormChanges = async (formId: number | null, formFields: IFormField[]) => {
        if (formId !== null && formId !== undefined) {
            const updatedForm: IForm = { ...form, formFields };
            await FormService.updateForm(formId, updatedForm);
        }
    };

    if (!form || formFields.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <div className="form-editor">
            <h1>{form?.title || "FormUser Editor"}</h1>
            {formFields.length > 0 ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="formFields">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {formFields.map((field, index) => (
                                    field.id ? (
                                        <Draggable
                                            key={field.id.toString()}
                                            draggableId={field.id.toString()}
                                            index={index}
                                        >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="form-field-card"
                                            >
                                                <p>{field.fieldName}</p>
                                            </div>
                                        )}
                                    </Draggable>
                                    ) : (
                                        <div key={index}>Error: Field is missing an ID</div>
                                    )
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <p>No form fields available.</p>
            )}
            <button onClick={() => form && saveFormChanges(form.id || null, formFields)}>Save</button>
        </div>
    );
};

export default FormEditor;
