import {useEffect, useState} from "react";
import {CategoryService} from "../../services/category.service";
import {ICategory} from "../../interfaces/Category";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const TableCategories: React.FC = () => {
    const {t} = useTranslation(['home']);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const categoryService = new CategoryService();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await categoryService.getAllCategories();
            setCategories(response.data);
        } catch (error) {
            console.error('Fehler beim Laden der Kategorien:', error);
        }
    };

    return (
        <div
            className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto mb-6">
                <table className="w-full table-auto">
                    <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Event Name")}
                        </th>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                            {t("Description")}
                        </th>
                        <th className="py-4 px-4 font-medium text-black dark:text-white">
                            {t("Actions")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map((category) => (
                        <tr key={category.id}>
                            <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                <Link to={`/categories/${category.id}/bookings`}
                                      className="text-primary dark:text-meta-3">
                                    {category.name}
                                </Link>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <p className="text-black dark:text-white">
                                    {category.description}
                                </p>
                            </td>
                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                <div className="flex items-center space-x-3.5">
                                    <Link to={`/categories/${category.id}/bookings`} className="hover:text-white">
                                        {t("View Category")}
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableCategories;
