import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import TableBookings from "../components/Tables/TableBookings";

const Bookings = () => {
  const { t } = useTranslation(['home']);
  return (
    <>
      <Breadcrumb pageName={t("Bookings")} />

      <div className="flex flex-col gap-10">
        <TableBookings />
      </div>
    </>
  );
};

export default Bookings;
