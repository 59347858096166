import { IEvent } from "./Event";

export interface ILocation {
    id?: number | null;
    event: IEvent | null;
    locationName: string;
    description: string;
    address: string;
    city: string;
    zip: string;
    state: string;
    country: string;
    updatedAt: string;
    createdAt: string;
}

export class Location implements ILocation {
    public id: null;
    public event: null;
    public locationName: string;
    public description: string;
    public address: string;
    public city: string;
    public zip: string;
    public state: string;
    public country: string;
    public updatedAt: string;
    public createdAt: string;

    constructor() {
        this.id = null;
        this.event = null;
        this.locationName = "";
        this.description = "";
        this.address = "";
        this.city = "";
        this.zip = "";
        this.state = "";
        this.country = "";
        this.updatedAt = "";
        this.createdAt = "";
    }
}
