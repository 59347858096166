import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormService } from '../services/form.service';
import { IForm } from '../interfaces/Form';

const FormsOverview: React.FC = () => {
    const [forms, setForms] = useState<IForm[]>([]);

    useEffect(() => {
        const fetchForms = async () => {
            try {
                const response = await FormService.getAllForms();
                setForms(response);
            } catch (error) {
                console.error('Error fetching forms:', error);
            }
        };
        fetchForms();
    }, []);

    return (
        <div className="forms-overview">
            <h1>Forms Overview</h1>
            <div className="form-list">
                {forms.map((form) => (
                    <Link to={`/forms/${form.id}/edit`} key={form.id} className="form-card">
                        <h2>{form.title}</h2>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default FormsOverview;
