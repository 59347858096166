import { useDispatch, useSelector } from "react-redux"
import { IProject, Project } from "../../interfaces/Project";
import { ProjectState, setProjectData, setProjects } from "../../features/slices/projectSlice";
import { useEffect } from "react";
import { ProjectService } from "../../services/project.service";
import { useTranslation } from "react-i18next";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa"
import { Link } from "react-router-dom";
import {toast} from "react-hot-toast";

const TableProjects = () => {
  const { t } = useTranslation(['home']);

  const { project } = useSelector((state: { project: ProjectState }) => state);

  useEffect(() => {
    fetchData() // eslint-disable-next-line
  }, [])

  const projectService = new ProjectService();

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const res: IProject[] = await projectService.getAll()
      dispatch(setProjects(res))
    } catch (error) {
      console.log('Error to failed load ==>', error)
    }
  }

  const onClickDelete = (item: IProject) => {
    const confirmed = window.confirm('Are you sure you want to delete?');
    if (confirmed) {
      fetchDelete(item);
    }
  }

  const fetchDelete = async (item: IProject) => {
    try {
      await projectService.delete(item)

      toast.success('The item has been deleted')

      fetchData()

    } catch (error) {
      console.log('Error to failed load ==>', error)
    }
  }

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">

      <div className="flex justify-start gap-4.5 mb-6.5">
        <Link to={"/project-add"} className="btn btn-primary" onClick={() => dispatch(setProjectData(new Project()))}>
          {t("Add new project")}
        </Link>
      </div>

      <div className="max-w-full overflow-x-auto mb-6">
        <table className="w-full table-auto">
          <thead>
          <tr className="bg-gray-2 text-left dark:bg-meta-4">
            <th className="min-w-[70px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
              {t("Project Id")}
            </th>
            <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
              {t("Project Number")}
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              {t("Language")}
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              {t("Project Type")}
            </th>
            <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
              {t("Project Status")}
            </th>
            <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
              {t("Start Date")}
            </th>
            <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
              {t("End Date")}
            </th>
            <th className="py-4 px-4 font-medium text-black dark:text-white">
              {t("Actions")}
            </th>
          </tr>
          </thead>
          <tbody>
          {project.list.map((item: IProject, key) => {
            return (
                <tr key={key}>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <Link to={'/project-edit'} className="text-primary dark:text-meta-3"
                          onClick={() => dispatch(setProjectData(item))}>
                      {item.id}
                    </Link>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <p className="text-black dark:text-white">
                      {item.countryCode}-{item.projectNr}-{item.projectType === "INTERNAL" ? "I" : "E"}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {item.language}_{item.countryCode}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {item.projectType === "INTERNAL" ? t("Internal") : t("External")}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p
                        className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${item.projectStatus === "PUBLISHED" ? 'bg-success text-success' : item.projectStatus === "DRAFT" ? 'bg-warning text-warning' : 'bg-error text-error'}}`}>
                      {item.projectStatus === "PUBLISHED" ? t("Published") : item.projectStatus === "DRAFT" ? t("Draft") : t("Review")}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {new Intl.DateTimeFormat('de-DE', {dateStyle: 'medium'}).format(new Date(item.startDate))}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {new Intl.DateTimeFormat('de-DE', {dateStyle: 'medium'}).format(new Date(item.endDate))}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <div className="flex items-center space-x-3.5">
                      <Link to={`/project-view/${item.id}`} className="hover:text-meta-3">
                        <FaEye/>
                      </Link>

                      <Link to={'/project-edit'} className="hover:text-meta-3"
                            onClick={() => dispatch(setProjectData(item))}>
                        <FaEdit/>
                      </Link>

                      <button className="hover:text-meta-3" onClick={() => onClickDelete(item)}>
                        <FaTrashAlt/>
                      </button>
                    </div>
                  </td>
                </tr>
            )
          })}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default TableProjects;
