import React from 'react';
import { ILocation, Location } from "../../interfaces/Location";
import { useDispatch, useSelector } from "react-redux";
import { LocationState, setLocationData, setLocations } from '../../features/slices/locationSlice';
import { LocationService } from '../../services/location.service';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import Breadcrumb from "../Breadcrumbs/Breadcrumb";

export const FormLocation = () => {

    let navigate = useNavigate();

    const { t } = useTranslation(['home']);

    const { location } = useSelector((state: { location: LocationState }) => state);

    const [errorForm, setErrorForm] = useState({
        locationName: false,
        description: false,
        address: false,
        city: false,
        zip: false,
        state: false,
        country: false
    })

    const dispatch = useDispatch();

    const locationService = new LocationService();

    const setFormValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setLocationData({ ...location.data, [event.target.id]: event.target.value }))
    }

    const isValidForm = () => {

        const error = { locationName: false, description: false, address: false, city: false, zip: false, state: false, country: false }

        if (!location.data.locationName) error.locationName = true
        if (!location.data.description) error.description = true
        if (!location.data.address) error.address = true
        if (!location.data.city) error.city = true
        if (!location.data.zip) error.zip = true
        if (!location.data.state) error.state = true
        if (!location.data.country) error.country = true

        setErrorForm(error)

        return error.locationName || error.description || error.address || error.city || error.zip || error.state || error.country;
    }

    const fetchUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            const data: ILocation = await locationService.put(location.data)
            // add item
            const dataArray: ILocation[] = [...location.list]
            // search index 
            let index: number = dataArray.findIndex((item: ILocation) => item.id === data.id)
            // replace item 
            dataArray.splice(index, 1, data);
            //update item
            dispatch(setLocations(dataArray))
            // for clean form
            //dispatch(setData(new Location()))

            toast.success(t('The data has been updated'))
            navigate("/locations")
        } catch (error) {
            console.log(error)
        }
    }

    const fetchCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            // valid fields 
            if (isValidForm()) return null;

            const data: ILocation = await locationService.post(location.data)
            // for clean form
            dispatch(setLocationData(new Location()))
            // add item
            const dataArray: ILocation[] = [...location.list]
            dataArray.push(data)
            dispatch(setLocations(dataArray))

            toast.success('The data has been saved')
            navigate("/locations")
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                toast.error(error.response.data.error)
            }
        }
    }

    const inputCSS = "w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
    const labelCSS = "mb-3 block text-sm font-medium text-black dark:text-white"
    const inputError = "border-red-400"

    return (
        <div className="mx-auto max-w-270">
            <Breadcrumb pageName={t("Add Project")}/>

            <div className="grid grid-cols-5 gap-8">
                <div className="col-span-5 xl:col-span-5">
                    <div
                        className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                                {t("Add Project")}
                            </h3>
                        </div>

                        <div className="p-7">
                            <form onSubmit={(e) => location.data.id ? fetchUpdate(e) : fetchCreate(e)}>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800"
                                           htmlFor="locationName">{t("Location Name")}</label>
                                    <div className="relative">
                                        <input
                                            id="locationName"
                                            type="text"
                                            placeholder={t("Location Name")}
                                            value={location.data.locationName}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.locationName ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.locationName &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}

                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800"
                                           htmlFor="description">{t("Description")}</label>
                                    <div className="relative">
                                        <input
                                            id="description"
                                            type="text"
                                            placeholder={t("Description")}
                                            value={location.data.description}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.description ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.description &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800" htmlFor="address">{t("Address")}</label>
                                    <div className="relative">
                                        <input
                                            id="address"
                                            type="text"
                                            placeholder={t("Address")}
                                            value={location.data.address}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.address ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.address &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800" htmlFor="zip">{t("Zip")}</label>
                                    <div className="relative">
                                        <input
                                            id="zip"
                                            type="text"
                                            placeholder={t("12345")}
                                            value={location.data.zip}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.zip ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.zip &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800" htmlFor="city">{t("City")}</label>
                                    <div className="relative">
                                        <input
                                            id="city"
                                            type="text"
                                            placeholder={t("City")}
                                            value={location.data.city}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.city ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.city &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800" htmlFor="state">{t("State")}</label>
                                    <div className="relative">
                                        <input
                                            id="state"
                                            type="text"
                                            placeholder={t("State")}
                                            value={location.data.state}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.state ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.state &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="mb-5.5">
                                    <label className="mb-2 text-gray-800" htmlFor="country">{t("Country")}</label>
                                    <div className="relative">
                                        <input
                                            id="country"
                                            type="text"
                                            placeholder={t("Country")}
                                            value={location.data.country}
                                            onChange={(e) => setFormValue(e)}
                                            className={errorForm.country ? inputCSS + inputError : inputCSS}/>
                                        {errorForm.country &&
                                            <p className="mt-1 text-m text-red-400">{t("This field is required")}</p>}
                                    </div>
                                </div>

                                <div className="flex justify-end gap-4.5">
                                    <Link to={"/locations"}
                                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                                          type="submit">
                                        {t("Cancel")}
                                    </Link>

                                    <button
                                        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                                        type="submit">
                                        {location.data.id ? t("Save") : t("Create")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
